<template>
  <div class="dragzone-wrapper" :class="{ dragzoneHeight: showTableDetail }">
    <div
      class="uploader"
      id="dropz"
      v-loading="loading"
      element-loading-text="文件正在上传中,请稍后"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
    <div class="uploader-bg">
      <div class="dz-message">
        <img class="drog_img" src="@/assets/test/uploader@2x.png" alt />
        <div class="drog_text" v-if="!showTableDetail">
          <span class="drog_text-blue">点击上传, 或将文件拖拽到此处</span>
        </div>
        <span class="acceptable_text" v-if="!showTableDetail"
          >支持：Excel/HTML/文件夹</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "@/common/js/dropzone.js";
import { uploadForTestUrlAiAll } from "@/api/api";
import { mapState } from "vuex";

export default {
  props: {
    uuid: String,
    showTableDetail: Boolean,
    nsrsbh: [Number, String],
    actualZq: [Number, String]
  },
  computed: {
    ...mapState(["companyInfo"]),
    ...mapState("UploadTable", ["zq"]),
    ...mapState("UploadTable", ["againUploadZq"]),
  },

  async mounted() {
    await this.$store.cache.dispatch("loadCompanyInfo");
    const _this = this;
    this.myDropzone = new Dropzone("#dropz", { 
      url: uploadForTestUrlAiAll(
        // sessionStorage.getItem("nsrsbh"),
        this.nsrsbh,
        this.againUploadZq,
        this.uuid,
        // this.companyInfo.nsrsbm,
        // this.companyInfo.kjzd,
        sessionStorage.getItem("qykjzd"),
        2
        // "qykjzd",
      ), // 文件提交地址
      method: "post", // 也可用put
      paramName: "file", // 要传输的文件参数的名称
      maxFiles: 2000, // 一次性上传的文件数量上限
      maxFilesize: 15, // 文件大小，单位：MB
      uploadMultiple: true, //如果您将选项 uploadMultiple设置为true，则Dropzone将附加[]到名称。
      acceptedFiles: ".xlsx,.xls,.html,.htm", // 上传的类型
      addRemoveLinks: true,
      parallelUploads: 1, // 一次上传的文件数量
      //previewsContainer:"#preview", // 上传图片的预览窗口
      //dictDefaultMessage: "拖动文件至此或者点击上传",
      dictMaxFilesExceeded: "您最多只能上传35个文件！",
      dictResponseError: "文件上传失败!",
      dictInvalidFileType: "文件类型只能是*.xlsx,*.xls,*.html,*.htm",
      dictFallbackMessage: "浏览器不受支持",
      dictFileTooBig: "文件过大上传文件最大支持.",
      dictRemoveLinks: "删除",
      dictCancelUpload: "取消",
      //dictRemoveFile: "删除", //删除按钮显示的文字
      init: function () {
        //第二个参数为后台返回的数据
        this.on("successmultiple", function (file, data) {
          console.log(_this.againUploadZq)
          if (data.code != 200) {
            _this.$message.error(data.message);
            return;
          }

          // // 返回的时候，显示进度
          // // 成功一张，则statusNumber+1
          // _this.statusNumber += 1;
          // //
          // _this.$emit("changeLength", {
          //   length: _this.statusLength,
          //   status: _this.statusNumber,
          // });

          _this.$emit("showDetailBox");
          const succData = [];

          for (const [, succ] of Object.entries(data.data.succData)) {
            for (const item of succ) {
              succData.push(item);
            }
          }

          // 递归上传的多组错误数据放一起

          if (data.data.errorData.length > 0) {
            _this.errorList.data.errorData = _this.errorList.data.errorData.concat(
              data.data.errorData
            );
          }

          // 递归上传的多组正确数据放一起
          if (data.data.succData["所得税表"]) {
            _this.errorList.data.succData[
              "所得税表"
            ] = _this.errorList.data.succData["所得税表"].concat(
              data.data.succData["所得税表"]
            );
          }
          if (data.data.succData["财务表"]) {
            _this.errorList.data.succData[
              "财务表"
            ] = _this.errorList.data.succData["财务表"].concat(
              data.data.succData["财务表"]
            );
          }
          if (data.data.succData["余额表"]) {
            _this.errorList.data.succData[
              "余额表"
            ] = _this.errorList.data.succData["余额表"].concat(
              data.data.succData["余额表"]
            );
          }
          if (data.data.succData["增值税表"]) {
            _this.errorList.data.succData[
              "增值税表"
            ] = _this.errorList.data.succData["增值税表"].concat(
              data.data.succData["增值税表"]
            );
          }
          if (data.data.succData.length > 0) {
            _this.errorList.data.succData = _this.errorList.data.succData.concat(
              data.data.succData
            );
          }

          // _this.$emit("onUpload", file, data);
          let successCount = 0;
          let failedCount = Object.entries(data.data.errorData).length;
          _this.failedCount =
            Object.entries(data.data.errorData).length + _this.failedCount;
          let needAdjustCount = 0;

          succData.forEach((v) => {
            if (v.uploadStatus == "待修改") {
              needAdjustCount++;
              _this.needAdjustCount++;
            } else {
              successCount++;
              _this.successCount++;
            }
          });
          let total = successCount + failedCount;
          // let msg = `总计上传${total}张表格 其中${successCount}张成功,${failedCount}张失败,${needAdjustCount}张需要调整`;

          // _this.$alert(msg);

          if (_this.errors.length > 0) {
            _this.$message.error(Array.from(new Set(_this.errors)).join("\n"));
          }
        });

        this.on("completemultiple", function (file) {
          // 返回的时候，显示进度
          // 成功一张，则statusNumber+1
          _this.statusNumber += 1;
          _this.$emit("changeLength", {
            length: _this.statusLength,
            status: _this.statusNumber,
          });

          _this.time = 0;
          _this.loading = false;
          this.element.classList.remove("drag-hover");
        });

        this.on("processingmultiple", function (file) {
          _this.loading = true;
          // 上传文件时触发的事件
        });
        this.on("success", (file, data) => {
          // if (data.data.errorData.length == 0) {
          //   setTimeout(() => {
          //     _this.$message.success(`${file.name} 上传成功`);
          //   }, _this.time);
          //   _this.time = _this.time += 100;
          // }
          // 上传成功触发的事件
        });
        // this.on("errormultiple", function (file, data) {
        //   //console.log(data);
        //   let msg = `${file.name} 上传失败`;
        //   if (data) {
        //     _this.errors.push(data);
        //   }

        //   // setTimeout(() => {
        //   //   _this.$message.error(`${file.name} 上传失败`);
        //   // }, _this.time);
        //   // _this.time = _this.time += 100;
        // });
        this.on("error", function (file, data) {
          //console.log(data);
          let msg = `${file.name} 上传失败`;
          if (data) {
            msg += data;
          }

          //如果有错误的表格则弹出弹出框
          _this.$message.error(msg);

          // setTimeout(() => {
          //   _this.$message.error(`${file.name} 上传失败`);
          // }, _this.time);
          // _this.time = _this.time += 100;
        });
        this.on("removedfile", function (file) {
          // 删除文件时触发的方法
        });
        this.on("dragenter", function () {
          this.element.classList.add("drag-hover");
        });
        this.on("dragleave", function () {
          this.element.classList.remove("drag-hover");
        });
        this.on("complete", function (file) {
          this.removeFile(file);
        });
        // 上传结束之后
        this.on("queuecomplete", function (file) {
          // 上传结束之后，关闭进度，将上传成功的数量置为0
          _this.$emit("overUpload");
          _this.statusNumber = 0;
          setTimeout(() => {
            _this.$emit("onUpload", file, _this.errorList);

            let msg = `总计上传${
              _this.successCount + _this.failedCount + _this.needAdjustCount
            }张表格 其中${_this.successCount}张成功,${
              _this.failedCount
            }张失败,${_this.needAdjustCount}张需要调整`;
            if (
              _this.successCount > 0 ||
              _this.failedCount > 0 ||
              _this.needAdjustCount > 0
            ) {
              _this.$alert(msg);
            }

            _this.totalOver = 0;
            _this.successCount = 0;
            _this.failedCount = 0;
            _this.needAdjustCount = 0;
            _this.errorList.data.errorData = [];
            _this.errorList.data.succData = {
              所得税表: [],
              财务表: [],
              余额表: [],
              增值税表: [],
            };
          }, 800);
        });
        // addedfile
        this.on("addedfiles", function (file) {
          _this.totalOver = file.length;
          _this.$emit("changeLength", {
            length: file.length,
            status: _this.statusNumber,
          });
          _this.statusLength = file.length;
        });
      },
    });
  },
  destroyed() {
    this.myDropzone.off();
    this.myDropzone.destroy();
  },
  data: function () {
    return {
      myDropzone: null,
      loading: false,
      infoNsrsbm: sessionStorage.getItem("nsrsbh"),
      errors: [],
      statusNumber: 0,
      statusLength: 0,
      totalOver: 0,
      successCount: 0,
      failedCount: 0,
      needAdjustCount: 0,
      errorList: {
        data: {
          errorData: [],
          succData: {
            所得税表: [],
            财务表: [],
            余额表: [],
            增值税表: [],
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
>>> .dz-preview {
  display: none !important;
}

.dragzone-wrapper {
  position: relative;
  width: 100%;
  height: 270px;
  background: rgba(47, 84, 235, 0.1);
  border-radius: 4px;
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .dz-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 270px;

    .drog_img {
      width: 108px;
      height: 72px;
      flex-grow: 0;
      margin-bottom: 16px;
    }

    .right-box {
      text-align: left;
      margin-left: 48px;

      .drog_text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;

        span {
          color: #2f54eb;
        }
      }

      .acceptable_text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.43);
        line-height: 22px;
      }
    }
  }

  .uploader {
    border: 1px dashed rgba(47, 84, 235, 1);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    right: 0px;
    bottom: 0;
  }

  .uploader.drag-hover {
    border: 1px solid rgba(47, 84, 235, 1);
  }

  .uploader-bg {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.43);
  }
}

.drog_text {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 22px;
}

.drog_text-blue {
  color: #2F54EB;
}

.dragzoneHeight {
  height: 634px;
}
</style>
